import React, {useRef, useState} from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';
import {TickSquare, Mobile, Eye, EyeSlash, UserEdit, Sms} from 'iconsax-react';
import UserApi from "../../services/UserApi";
import AlertAction from "../../components/AlertAction";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import imageBgLogin from '../../images/bg-login.png';
import "./Login.css";
import AdminApi from '../../services/AdminApi';

//----------------------------------------------------------------------------------------------------------------------

function Login()
{
    const auth = useAuth();
    const alertAction = useRef(); // init alert

    const [formPhoneEmail, setFormPhoneEmail] = useState("");
    const [formPassword, setFormPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [isRemember, setIsRemember] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [formLoading, setFormLoading] = useState(false);

    // send data to server
    const submitLogin = () =>
    {
        if(formPhoneEmail !== "" && formPassword !== "") // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params = {'phoneEmail': formPhoneEmail, 'password': formPassword};
            AdminApi.login(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        auth.authentication({admin: response.data.admin, name: response.data.name, token: response.data.token, isRemember: isRemember});
                        setIsLogin(true);
                    }
                    else
                    {
                        setFormLoading(false); // show btn & hide loading
                        alertAction.current.showAlert(response.message); // show error
                    }
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    // redirect to panel if admin loggedIn
    if(isLogin)
    {
        return <Navigate to="/programs" />;
    }

    // page content ----------------------------------------------------------------------------------------------------
    return (
        <div className="page-login">
            <div className="col-left">
                <img src={imageBgLogin} />
            </div>

            <div className="col-right">
                <div className="section-top">
                    <div className="section-title">
                        <div className="title-box">
                            <span className="title">Login</span>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-input-box">
                                    <input type="text" className="form-input" placeholder="Phone Number 989123219876 or Email" onChange={(e) => setFormPhoneEmail(e.target.value)} />
                                    <Mobile variant="TwoTone" className="icon" />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-input-box">
                                    <input type={passwordType} className="form-input" placeholder="Password" autoComplete="new-password" onChange={(e) => setFormPassword(e.target.value)} />
                                    {passwordType === 'password' ? <Eye variant="TwoTone" className="icon" onClick={() => setPasswordType('text')} /> : <EyeSlash variant="TwoTone" className="icon" onClick={() => setPasswordType('password')} />}
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-forget-remember">
                                    <label className="form-checkbox" onClick={() => setIsRemember(!isRemember)}>{isRemember ? <TickSquare variant="Bulk" className="icon" /> : <span className="icon"></span>} Remember Me</label>

                                    <Link to="/forget-password" className="link-custom border-bottom">Forget Password?</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="btn-box">
                    <button onClick={submitLogin} type="button" className="btn-custom full">Login</button>
                </div>
            </div>

            {/* loading */}
            {formLoading ? <LoadingOver /> : null}

            {/* alert */}
            <AlertAction ref={alertAction} />
        </div>
    );
}

export default Login;
