import RestApi from "./RestApi";

export default
{
    list: async function(params) { return RestApi.get("/coach", params); },
    show: async function(id) { return RestApi.get("/coach/" + id); },
    store: async function(params) { return RestApi.restUpload("/coach", params); },
    update: async function(id, params) { return RestApi.restUpload("/coach/" + id, params); },
    destroy: async function(id) { return RestApi.delete("/coach/" + id); },
}
