import React, {useEffect, useRef, useState} from 'react';
import { Link, Navigate } from 'react-router-dom';
import {ArrowDown2, TickSquare, Mobile, Eye, EyeSlash, UserEdit, Sms} from 'iconsax-react';
import AdminApi from "../../services/AdminApi";
import CountryApi from "../../services/CountryApi";
import { useAuth } from '../../contexts/Auth';
import AlertAction from "../../components/AlertAction";
import DialogQuestion from "../../components/DialogQuestion/DialogQuestion";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import imageBgForgetPassword from '../../images/bg-forget-password.png';
import imageBgVerification from '../../images/bg-verification.png';
import "./ForgetPassword.css";

//----------------------------------------------------------------------------------------------------------------------

function ForgetPassword()
{
    const auth = useAuth();
    const alertAction = useRef(); // init alert

    const [formPhone, setFormPhone] = useState("");
    const [formPassword, setFormPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [formLoading, setFormLoading] = useState(false);
    const [isStepOne, setIsStepOne] = useState(true);
    const [isForgetPassword, setIsForgetPassword] = useState(false);

    const [formVerifyCode1, setFormVerifyCode1] = useState("");
    const [formVerifyCode2, setFormVerifyCode2] = useState("");
    const [formVerifyCode3, setFormVerifyCode3] = useState("");
    const [formVerifyCode4, setFormVerifyCode4] = useState("");

    const [verifyCodeTimer, setVerifyCodeTimer] = useState('');
    const [isActiveResend, setIsActiveResend] = useState(true);
    let intervalVerifyCodeTimer = null;
    let timerValue = 0;

    // next step
    const submitNext = () =>
    {
        if(isActiveResend)
        {
            if(formPhone !== "" && formPassword.length >= 5 && formPassword.length <= 20) // check data is correct
            {
                setFormLoading(true); // hide btn & show loading

                // send data
                const params = {'phone': formPhone};
                AdminApi.forgetPasswordSendVerifyCode(params).then
                (
                    function(response)
                    {
                        if(response.status === 200)
                        {
                            alertAction.current.showAlert("Forget Password verify code sent to your phone", "success"); // show success
                            setIsStepOne(false);
                            setIsActiveResend(false);

                            // set timer for resend code
                            timerValue = 120;
                            intervalVerifyCodeTimer = setInterval
                            (
                                function()
                                {
                                    timerValue -= 1;
                                    setVerifyCodeTimer('Resend (' + timerValue + ')');

                                    if(timerValue <= 0)
                                    {
                                        clearInterval(intervalVerifyCodeTimer);
                                        setVerifyCodeTimer('Resend');
                                        setIsActiveResend(true);
                                    }
                                },
                                1000
                            );
                        }
                        else
                        {
                            alertAction.current.showAlert(response.message); // show error
                        }

                        setFormLoading(false); // show btn & hide loading
                    }
                ).catch
                (
                    function(error)
                    {
                        setFormLoading(false); // show btn & hide loading
                        alertAction.current.showAlert("There is a Problem"); // show error
                    }
                );
            }
            else
            {
                alertAction.current.showAlert("Please complete information"); // show error
            }
        }
    };

    // send data to server
    const submitForgetPassword = () =>
    {
        const formVerifyCode = formVerifyCode1 + '' + formVerifyCode2 + '' + formVerifyCode3 + '' + formVerifyCode4;

        if(formVerifyCode.length === 4) // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params = {'phone': formPhone, 'password': formPassword, 'verifyCode': formVerifyCode};
            AdminApi.forgetPassword(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("New Password Saved.", "success"); // show success

                        setIsForgetPassword(true);
                    }
                    else
                    {
                        setFormLoading(false); // show btn & hide loading
                        alertAction.current.showAlert(response.message); // show error
                    }
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    // next input
    const autoTab = (e: ChangeEvent<HTMLInputElement>) =>
    {
        if(`${e.target.value.length}` === e.target.getAttribute("maxlength"))
        {
            var inputs = document.getElementsByClassName("form-forget-password-verification-code");

            for (let i = 0; i < inputs.length; i++)
            {
                if (e.target === inputs[i])
                {
                    i++;
                    if (i < inputs.length)
                    {
                        let next = inputs[i];
                        next.focus();
                    }
                }
            }
        }
    };

    // redirect to login
    if(isForgetPassword)
    {
        return <Navigate to="/login" />;
    }

    // page content ----------------------------------------------------------------------------------------------------
    return (
        <div className="page-forget-password">
            {
                isStepOne ?
                    <>
                        <div className="col-left">
                            <img src={imageBgForgetPassword} />
                        </div>

                        <div className="col-right">
                            <div className="section-top">
                                <div className="section-title">
                                    <div className="title-box">
                                        <span className="title">Forget Password</span>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-input-box">
                                                <input type="text" className="form-input" placeholder="Phone Number   989123219876" onChange={(e) => setFormPhone(e.target.value)} />
                                                <Mobile variant="TwoTone" className="icon" />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="form-input-box">
                                                <input type={passwordType} className="form-input" placeholder="New Password" autoComplete="new-password" onChange={(e) => setFormPassword(e.target.value)} />
                                                {passwordType === 'password' ? <Eye variant="TwoTone" className="icon" onClick={() => setPasswordType('text')} /> : <EyeSlash variant="TwoTone" className="icon" onClick={() => setPasswordType('password')} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="btn-box">
                                <button onClick={submitNext} type="button" className="btn-custom full">Next</button>
                                <Link to="/login" className="btn-custom t2 full">Back</Link>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="col-left">
                            <img src={imageBgVerification} />
                        </div>

                        <div className="col-right">
                            <div className="section-top">
                                <div className="section-title">
                                    <div className="title-box">
                                        <span className="title">verification</span>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="form-input-box">
                                                <input type="text" className="form-input no-icon text-center form-forget-password-verification-code" maxLength="1" value={formVerifyCode1} onChange={(e) => {setFormVerifyCode1(e.target.value); autoTab(e)}} />
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <div className="form-input-box">
                                                <input type="text" className="form-input no-icon text-center form-forget-password-verification-code" maxLength="1" value={formVerifyCode2} onChange={(e) => {setFormVerifyCode2(e.target.value); autoTab(e)}} />
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <div className="form-input-box">
                                                <input type="text" className="form-input no-icon text-center form-forget-password-verification-code" maxLength="1" value={formVerifyCode3} onChange={(e) => {setFormVerifyCode3(e.target.value); autoTab(e)}} />
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <div className="form-input-box">
                                                <input type="text" className="form-input no-icon text-center form-forget-password-verification-code" maxLength="1" value={formVerifyCode4} onChange={(e) => {setFormVerifyCode4(e.target.value); autoTab(e)}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="btn-box">
                                <button onClick={submitForgetPassword} type="button" className="btn-custom full">Submit</button>
                                <button onClick={submitNext} type="button" className="btn-custom t2 full">{verifyCodeTimer}</button>
                            </div>
                        </div>
                    </>
            }

            {/* loading */}
            {formLoading ? <LoadingOver /> : null}

            {/* alert */}
            <AlertAction ref={alertAction} />
        </div>
    );
}

export default ForgetPassword;
