import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useRef, useState} from "react";
import AlertAction from "../../components/AlertAction";
import CoachApi from "../../services/CoachApi";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import "./Add.css";
import {Sort} from "iconsax-react";

//--------------------------------------------------

function Add(props)
{
    // set page title & page path
    usePage().pageData({title: Menus.coachAdd.title, activeMenu: Menus.coachAdd.className});

    //
    const alertAction = useRef(); // init alert

    const [formLoading, setFormLoading] = useState(false);
    const [formPriority, setFormPriority] = useState("");
    const [formTitleEn, setFormTitleEn] = useState("");
    const [formTitleFa, setFormTitleFa] = useState("");

    // send data to server
    const submitCoach = () =>
    {
        if(formTitleEn !== "" && formTitleFa !== "") // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            let params = new FormData();
            params.append('formTitleEn', formTitleEn);
            params.append('formTitleFa', formTitleFa);
            params.append('formPriority', formPriority);
            CoachApi.store(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Coach Updated", "success"); // show success

                        setFormTitleEn("");
                        setFormTitleFa("");
                        setFormPriority("");
                    }
                    else
                    {
                        alertAction.current.showAlert(response.message); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    //
    return (
        <div className="page-content page-coach-add">
            <div className="section-title">
                <div className="title-box">
                    <span className="title">Add </span>
                    <span className="title-t2">Coach</span>
                </div>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="form-label">Title En</div>
                            <div className="form-input-box">
                                <input type="text" className="form-input" value={formTitleEn} onChange={(e) => setFormTitleEn(e.target.value)} />
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-label">Title Fa</div>
                            <div className="form-input-box">
                                <input type="text" className="form-input" value={formTitleFa} onChange={(e) => setFormTitleFa(e.target.value)} dir="rtl" />
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-label">Priority</div>
                            <div className="form-input-box">
                                <input type="text" className="form-input" placeholder="Priority" value={formPriority} onChange={(e) => setFormPriority(e.target.value)} />
                                <Sort variant="TwoTone" className="icon" />
                            </div>
                        </div>
                    </div>
                </div>


                {/* loading */}
                {formLoading ? <LoadingOver /> : null}


                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>

            <div className="section-footer flex-end">
                <button onClick={submitCoach} type="button" className="btn-custom t2">Save Change</button>
            </div>
        </div>
    );
}

export default Add;
