import RestApi from "./RestApi";

export default
{
    list: async function(params) { return RestApi.get("/program", params); },
    show: async function(id) { return RestApi.get("/program/" + id); },
    store: async function(params) { return RestApi.restUpload("/program", params); },
    update: async function(id, params) { return RestApi.restUpload("/program/" + id, params); },
    destroy: async function(id) { return RestApi.delete("/program/" + id); },
}
