import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import AlertAction from "../../components/AlertAction";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import "./Setting.css";
import {PercentageSquare} from "iconsax-react";
import SettingApi from "../../services/SettingApi";

//--------------------------------------------------

function Setting(props)
{
    // set page title & page path
    usePage().pageData({title: Menus.setting.title, activeMenu: Menus.setting.className});

    //
    const alertAction = useRef(); // init alert

    const [settingDataLoading, setSettingDataLoading] = useState(true);

    const [formTaxDollar, setFormTaxDollar] = useState("");
    const [formTaxToman, setFormTaxToman] = useState("");
    const [formTwilioClientSid, setFormTwilioClientSid] = useState("");
    const [formTwilioClientToken, setFormTwilioClientToken] = useState("");
    const [formTwilioSid, setFormTwilioSid] = useState("");
    const [formStripeApiKey, setFormStripeApiKey] = useState("");
    const [formPaypingApiToken, setFormPaypingApiToken] = useState("");
    const [formImageLogo, setFormImageLogo] = useState('');
    const [formImageLogoName, setFormImageLogoName] = useState('');
    const [formImageFavicon, setFormImageFavicon] = useState('');
    const [formImageFaviconName, setFormImageFaviconName] = useState('');
    const [formLoading, setFormLoading] = useState(false);

    // load data
    const loadData = () =>
    {
        setSettingDataLoading(true); // show loading

        // get data
        SettingApi.show().then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setFormTaxDollar(response.data.taxDollar);
                    setFormTaxToman(response.data.taxToman);
                    setFormTwilioClientSid(response.data.twilioClientSid);
                    setFormTwilioClientToken(response.data.twilioClientToken);
                    setFormTwilioSid(response.data.twilioSid);
                    setFormStripeApiKey(response.data.stripeApiKey);
                    setFormPaypingApiToken(response.data.paypingApiToken);
                }

                setSettingDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setSettingDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    // send data to server
    const submitSetting = () =>
    {
        if(formTaxDollar > 0 && formTaxToman > 0) // check id & password is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            let params = new FormData();
            params.append('taxDollar', formTaxDollar);
            params.append('taxToman', formTaxToman);
            params.append('twilioClientSid', formTwilioClientSid);
            params.append('twilioClientToken', formTwilioClientToken);
            params.append('twilioSid', formTwilioSid);
            params.append('stripeApiKey', formStripeApiKey);
            params.append('paypingApiToken', formPaypingApiToken);
            params.append('formImageLogo', formImageLogo);
            params.append('formImageFavicon', formImageFavicon);

            SettingApi.update(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Setting Updated", "success"); // show success
                    }
                    else
                    {
                        alertAction.current.showAlert(response.message); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    //
    return (
        <div className="page-content page-setting">
            <div className="section-title">
                <div className="title-box">
                    <span className="title">Edit </span>
                    <span className="title-t2">Setting</span>
                </div>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="form-label">Tax Dollar</div>
                            <div className="form-input-box">
                                <input type="text" className="form-input" placeholder="Tax Dollar" value={formTaxDollar} onChange={(e) => setFormTaxDollar(e.target.value)} />
                                <PercentageSquare variant="TwoTone" className="icon" />
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-label">Tax Toman</div>
                            <div className="form-input-box">
                                <input type="text" className="form-input" placeholder="Tax Toman" value={formTaxToman} onChange={(e) => setFormTaxToman(e.target.value)} />
                                <PercentageSquare variant="TwoTone" className="icon" />
                            </div>
                        </div>

                        {/* twilio */}
                        <div className="col-4">
                            <div className="form-label">Twilio Client Sid</div>
                            <div className="form-input-box">
                                <input type="text" className="form-input" value={formTwilioClientSid} onChange={(e) => setFormTwilioClientSid(e.target.value)} />
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="form-label">Twilio Client Token</div>
                            <div className="form-input-box">
                                <input type="text" className="form-input" value={formTwilioClientToken} onChange={(e) => setFormTwilioClientToken(e.target.value)} />
                            </div>
                        </div>

                        <div className="col-4">
                            <div className="form-label">Twilio Sid</div>
                            <div className="form-input-box">
                                <input type="text" className="form-input" value={formTwilioSid} onChange={(e) => setFormTwilioSid(e.target.value)} />
                            </div>
                        </div>

                        {/* stripe */}
                        <div className="col-6">
                            <div className="form-label">Stripe Api Key</div>
                            <div className="form-input-box">
                                <input type="text" className="form-input" value={formStripeApiKey} onChange={(e) => setFormStripeApiKey(e.target.value)} />
                            </div>
                        </div>

                        <div className="col-6"></div>

                        {/* payping */}
                        <div className="col-6">
                            <div className="form-label">Payping Api Token</div>
                            <div className="form-input-box">
                                <input type="text" className="form-input" value={formPaypingApiToken} onChange={(e) => setFormPaypingApiToken(e.target.value)} />
                            </div>
                        </div>

                        <div className="col-6"></div>

                        {/* images */}
                        <div className="col-12">
                            <div className="form-input-box">
                                <label className="form-label">Image Logo (only png)</label>
                                <label className="btn-file" htmlFor="form-image-logo">Select Image {formImageLogoName !== '' ? ':: ' + formImageLogoName : ''}<input type="file" id="form-image-logo" onChange={(e) => { if(e.target.files.length > 0) { setFormImageLogo(e.target.files[0]); setFormImageLogoName(e.target.files[0].name); } } } /></label>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="form-input-box">
                                <label className="form-label">Image Favicon (only png)</label>
                                <label className="btn-file" htmlFor="form-image-favicon">Select Image {formImageFaviconName !== '' ? ':: ' + formImageFaviconName : ''}<input type="file" id="form-image-favicon" onChange={(e) => { if(e.target.files.length > 0) { setFormImageFavicon(e.target.files[0]); setFormImageFaviconName(e.target.files[0].name); } } } /></label>
                            </div>
                        </div>
                    </div>
                </div>


                {/* loading */}
                {formLoading || settingDataLoading ? <LoadingOver /> : null}


                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>

            <div className="section-footer flex-end">
                <button onClick={submitSetting} type="button" className="btn-custom t2">Save Change</button>
            </div>
        </div>
    );
}

export default Setting;
