import React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import range from 'lodash/range';
import './Pagination.css';

//--------------------------------------------------

function Pagination(props)
{
    const data = props.data;
    const loadData = props.loadData;

    const currentPageNumber = data != null ? parseInt(data.currentPageNumber) : 0;
    const totalPageNumber = data != null ? data.totalPages : 0;
    const totalRows = data != null ? data.totalRows : 0;
    const fromRowNumber = data != null ? (data.currentPageNumber - 1) * data.pageRows + 1 : 0;
    const toRowNumber = data != null ? (data.currentPageNumber - 1) * data.pageRows + data.currentPageRows : 0;
    const adjacent = 3;

    //
    return (
        <div className="pagination-me-box">
            {
                // data != null && data.list != null && data.list.length > 0 ?
                data != null ?
                    <>
                        <div className="pagination-me-data">Showing {fromRowNumber} to {toRowNumber} of {totalRows} entries</div>
                        {
                            totalPageNumber > 1 ?
                                <ul className="pagination-me">
                                    {
                                        (currentPageNumber > 1) ? <li className="icon"><span onClick={() => loadData(currentPageNumber - 1)}><ArrowBackIcon /></span></li> : null
                                    }
                                    {
                                        (totalPageNumber < 7 + (adjacent * 2)) ?
                                            <PaginationLink startPageNumber={1} endPageNumber={totalPageNumber} currentPageNumber={currentPageNumber} loadData={loadData} />
                                            :
                                            (
                                                (totalPageNumber > 5 + (adjacent * 2)) ?
                                                    (
                                                        (currentPageNumber < 1 + (adjacent * 2)) ?
                                                            <>
                                                                <PaginationLink startPageNumber={1} endPageNumber={4 + (adjacent * 2)} currentPageNumber={currentPageNumber} loadData={loadData} />
                                                                <li className="space"><span>...</span></li>
                                                                <PaginationLink startPageNumber={totalPageNumber - 1} endPageNumber={totalPageNumber} currentPageNumber={currentPageNumber} loadData={loadData} />
                                                            </>
                                                            :
                                                            ((totalPageNumber - (adjacent * 2) > currentPageNumber) && (currentPageNumber > (adjacent * 2))) ?
                                                                <>
                                                                    <PaginationLink startPageNumber={1} endPageNumber={2} currentPageNumber={currentPageNumber} loadData={loadData} />
                                                                    <li className="space"><span>...</span></li>
                                                                    <PaginationLink startPageNumber={currentPageNumber - adjacent} endPageNumber={currentPageNumber + adjacent} currentPageNumber={currentPageNumber} loadData={loadData} />
                                                                    <li className="space"><span>...</span></li>
                                                                    <PaginationLink startPageNumber={totalPageNumber - 1} endPageNumber={totalPageNumber} currentPageNumber={currentPageNumber} loadData={loadData} />
                                                                </>
                                                                :
                                                                <>
                                                                    <PaginationLink startPageNumber={1} endPageNumber={2} currentPageNumber={currentPageNumber} loadData={loadData} />
                                                                    <li className="space"><span>...</span></li>
                                                                    <PaginationLink startPageNumber={totalPageNumber - (2 + (adjacent * 2))} endPageNumber={totalPageNumber} currentPageNumber={currentPageNumber} loadData={loadData} />
                                                                </>
                                                    )
                                                    :
                                                    null
                                            )
                                    }
                                    {
                                        (currentPageNumber < totalPageNumber) ? (<li className="icon"><span onClick={() => loadData(currentPageNumber + 1)}><ArrowForwardIcon /></span></li>) : null
                                    }
                                </ul>
                                :
                                null
                        }
                    </>
                    :
                    null
            }
        </div>
    );
}

function PaginationLink(props)
{
    const loadData = props.loadData;
    const startPageNumber = props.startPageNumber;
    const endPageNumber = props.endPageNumber + 1;
    const currentPageNumber = props.currentPageNumber;

    return(
        range(startPageNumber, endPageNumber).map(pageNumber => (<li className={currentPageNumber === pageNumber ? "active" : ""} key={pageNumber}><span onClick={() => loadData(pageNumber)}>{pageNumber}</span></li>))
    );
}

export default Pagination;
