import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import AlertAction from "../../components/AlertAction";
import CoachApi from "../../services/CoachApi";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import "./Edit.css";
import {DollarSquare, Trash, AddSquare, Sort} from "iconsax-react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {useParams} from "react-router";
import General from "../../utils/General";

//--------------------------------------------------

function Edit(props)
{
    // get id
    let { coachId } = useParams();

    // set page title & page path
    usePage().pageData({title: Menus.coachs.title, activeMenu: Menus.coachs.className});

    //
    const alertAction = useRef(); // init alert

    const [coachDataLoading, setCoachDataLoading] = useState(true);
    const [activeLanguage, setActiveLanguage] = useState('en');

    const [formLoading, setFormLoading] = useState(false);
    const [formPriority, setFormPriority] = useState("");
    const [formTitleEn, setFormTitleEn] = useState("");
    const [formTitleFa, setFormTitleFa] = useState("");

    // load data
    const loadData = () =>
    {
        setCoachDataLoading(true); // show loading

        // get data
        CoachApi.show(coachId).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setFormTitleEn(response.data.titleEn);
                    setFormTitleFa(response.data.titleFa);
                    setFormPriority(response.data.priority);
                }

                setCoachDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setCoachDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    // send data to server
    const submitCoach = () =>
    {
        if(formTitleEn !== "" && formTitleFa !== "") // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            let params = new FormData();
            params.append('formTitleEn', formTitleEn);
            params.append('formTitleFa', formTitleFa);
            params.append('formPriority', formPriority);
            CoachApi.update(coachId, params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Coach Updated", "success"); // show success
                    }
                    else
                    {
                        alertAction.current.showAlert(response.message); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    //
    return (
        <div className="page-content page-coach-edit">
            <div className="section-title">
                <div className="title-box">
                    <span className="title">Add </span>
                    <span className="title-t2">Coach</span>
                </div>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="form-label">Title En</div>
                            <div className="form-input-box">
                                <input type="text" className="form-input" value={formTitleEn} onChange={(e) => setFormTitleEn(e.target.value)} />
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-label">Title Fa</div>
                            <div className="form-input-box">
                                <input type="text" className="form-input" value={formTitleFa} onChange={(e) => setFormTitleFa(e.target.value)} dir="rtl" />
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-label">Priority</div>
                            <div className="form-input-box">
                                <input type="text" className="form-input" placeholder="Priority" value={formPriority} onChange={(e) => setFormPriority(e.target.value)} />
                                <Sort variant="TwoTone" className="icon" />
                            </div>
                        </div>
                    </div>
                </div>


                {/* loading */}
                {formLoading || coachDataLoading ? <LoadingOver /> : null}


                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>

            <div className="section-footer flex-end">
                <button onClick={submitCoach} type="button" className="btn-custom t2">Save Change</button>
            </div>
        </div>
    );
}

export default Edit;
