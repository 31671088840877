import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link, useNavigate, useParams} from "react-router-dom";
import SortTable from "../../components/SortTable/SortTable";
import Pagination from "../../components/Pagination/Pagination";
import ProgramApi from "../../services/ProgramApi";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import General from "../../utils/General";
import IconButton from '@mui/material/IconButton';
import {Button, Container, Grid, TextField} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import DialogQuestion from "../../components/DialogQuestion/DialogQuestion";
import AlertAction from "../../components/AlertAction";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import "./Programs.css";
import UserApi from "../../services/UserApi";
import {DollarSquare, Trash, Edit, ArrowRight2} from "iconsax-react";

//--------------------------------------------------

function Programs(props)
{
    // set page title & page path
    usePage().pageData({title: Menus.programs.title, activeMenu: Menus.programs.className});

    //
    const [programData, setProgramData] = useState([]);
    const [programDataLoading, setProgramDataLoading] = useState(true);
    const [paginationData, setPaginationData] = useState({"currentPageNumber": 0, "totalPages": 0, "totalRows": 0, "pageRows": 0, "currentPageRows": 0});

    const [sortData, setSortData] = useState({});
    const [sortColName, setSortColName] = useState('');
    const [sortType, setSortType] = useState('');

    const [programId, setProgramId] = useState('');
    const [searchWord, setSearchWord] = useState('');

    const navigate = useNavigate();
    const alertAction = useRef(); // init alert
    const dialogQuestion = useRef(); // init alert delete

    // load data
    const loadData = (pageNumber = 1) =>
    {
        setProgramData(null); // set data to null
        setProgramDataLoading(true); // show loading

        // get data
        ProgramApi.list({ pageNumber: pageNumber }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setPaginationData({"currentPageNumber": response.data.currentPageNumber, "totalPages": response.data.totalPages, "totalRows": response.data.totalRows, "pageRows": response.data.pageRows, "currentPageRows": response.data.currentPageRows});

                    setProgramData(response.data.list);
                }

                setProgramDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setProgramDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    //
    const handleProgramDelete = (id) =>
    {
        dialogQuestion.current.show();

        setProgramId(id); // set program id for delete
    };

    const programDelete = () =>
    {
        dialogQuestion.current.hide(); // hide popup
        setProgramDataLoading(true); // show loading

        // get data
        ProgramApi.destroy(programId).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    alertAction.current.showAlert("Program Deleted", "success"); // show success

                    const pn = General.initPageNumber(paginationData);
                    if(pn > 0)
                    {
                        loadData(pn);
                    }
                    else
                    {
                        setProgramData(null);
                        setProgramDataLoading(false); // hide loading
                    }
                }
                else
                {
                    // alertAction.current.showAlert("Incorrect information"); // show error
                    alertAction.current.showAlert(response.message); // show error

                    setProgramDataLoading(false); // hide loading
                }
            }
        ).catch
        (
            function(error)
            {
                setProgramDataLoading(false); // hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    return (
        <div className="page-content page-programs">
            <div className="section-title">
                <div className="title-box">
                    <span className="title">Program </span>
                    <span className="title-t2">List</span>
                </div>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    programDataLoading ?
                        null
                        :
                        (
                            programData !== null && programData.length > 0 ?
                                <table className="table-data">
                                    <thead className="sticky">
                                    <tr>
                                        <th>title</th>
                                        {/*<th>Specialize</th>*/}
                                        <th>Type</th>
                                        {/*<th>Gender</th>*/}
                                        {/*<th>Gain or Loss</th>*/}
                                        <th>Training</th>
                                        <th>Priority</th>
                                        <th>Price Dollar</th>
                                        <th>Price Toman</th>
                                        <th className="w-80"></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        programData.map
                                        (
                                            (item, index) =>
                                            {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.titleEn}</td>
                                                        {/*<td>{item.special === 1 ? 'Specialize' : 'Non-Specialize'}</td>*/}
                                                        <td>{item.foodWorkout === 1 ? 'Workout' : 'Food'}</td>
                                                        {/*<td>{item.gender}</td>*/}
                                                        {/*<td>{item.gainLoss === 1 ? 'Loss' : 'Gain'}</td>*/}
                                                        <td>
                                                            {
                                                                {
                                                                    0: 'Home',
                                                                    1: 'Gym',
                                                                    2: 'Champion',
                                                                }[item.homeGym]
                                                            }
                                                        </td>
                                                        <td>{item.priority}</td>
                                                        <td>{item.price}</td>
                                                        <td>{item.priceToman}</td>
                                                        <td>
                                                            <Link to={'/program/edit/' + item.id}><Edit className="btn-icon-delete m-r-8" variant="Bulk" /></Link>
                                                            <Trash className="btn-icon-delete m-l-16" variant="Bulk" onClick={(e) => handleProgramDelete(item.id)} />
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    }
                                    </tbody>
                                </table>
                                :
                                <AlertDataEmpty />
                        )
                }


                {/* loading */}
                {programDataLoading ? <LoadingOver /> : null}


                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>


            {
                programData !== null && programData.length > 0 ?
                    <div className="section-footer">
                        <Pagination data={paginationData} loadData={loadData} />
                    </div>
                    :
                    null
            }

            {/* popup delete */}
            <DialogQuestion ref={dialogQuestion} accept={programDelete} title="Delete Program" des="Are you sure you want to delete the program?" buttonType="error" buttonAcceptText="Yes, Delete Program" buttonCloseText="No" />
        </div>
    );
}

export default Programs;
