import {DollarSquare, Profile2User, Setting2, UserSquare, HuobiToken, Sun, Messages1, User, UserAdd, Translate, DocumentText, ClipboardClose} from 'iconsax-react';

export default
{
    programAdd: {className: "program-add", link: "/program/add", title: "Add Program", icon: <ClipboardClose className="icon" variant="TwoTone" />, isMenu: true},
    programs: {className: "programs", link: "/programs", title: "Programs", icon: <DocumentText className="icon" variant="TwoTone" />, isMenu: true},
    coachAdd: {className: "coach-add", link: "/coach/add", title: "Add Coach", icon: <UserAdd className="icon" variant="TwoTone" />, isMenu: true},
    coachs: {className: "coachs", link: "/coachs", title: "Coachs", icon: <User className="icon" variant="TwoTone" />, isMenu: true},
    // programWorkout: {className: "program-workout", link: "/program/workout", title: "Workout Programs", icon: <Weight className="icon" variant="TwoTone" />, isMenu: true},
    // programFood: {className: "program-food", link: "/program/food", title: "Food Programs", icon: <Reserve className="icon" variant="TwoTone" />, isMenu: true},
    orders: {className: "orders", link: "/orders", title: "Orders", icon: <DollarSquare className="icon" variant="TwoTone" />, isMenu: true},
    chat: {className: "chat", link: "/chats", title: "Support", icon: <Messages1 className="icon" variant="TwoTone" />, isMenu: true},
    coolDown: {className: "cool-down", link: "/cool-down", title: "How to Cool Down", icon: <Sun className="icon" variant="TwoTone" />, isMenu: true},
    warmUp: {className: "warm-up", link: "/warm-up", title: "How to Warm Up", icon: <HuobiToken className="icon" variant="TwoTone" />, isMenu: true},
    users: {className: "user", link: "/users", title: "Users", icon: <Profile2User className="icon" variant="TwoTone" />, isMenu: true},
    translate: {className: "translate", link: "/translate", title: "Translate", icon: <Translate className="icon" variant="TwoTone" />, isMenu: true},
    setting: {className: "setting", link: "/setting", title: "Setting", icon: <Setting2 className="icon" variant="TwoTone" />, isMenu: true},
    profile: {className: "profile", link: "/profile", title: "Edit Profile", icon: <UserSquare className="icon" variant="TwoTone" />, isMenu: true},
};
