import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { SnackbarProvider } from 'notistack';
import App from "./App";

//--------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter>
        <SnackbarProvider maxSnack={10}>
            <App />
        </SnackbarProvider>
    </BrowserRouter>
);

serviceWorker.register();
