import React from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
// routers
import RouterPublic from "./RouterPublic";
import RouterPrivate from './RouterPrivate';
// pages
import Login from '../pages/Login/Login';
import ForgetPassword from "../pages/ForgetPassword/ForgetPassword";
import Profile from "../pages/Profile/Profile";
import Chats from "../pages/Chat/Chats";
import Chat from "../pages/Chat/Chat";
import CoolDown from "../pages/CoolDown/CoolDown";
import WarmUp from "../pages/WarmUp/WarmUp";
import ProgramAdd from "../pages/Program/Add";
import ProgramEdit from "../pages/Program/Edit";
import Programs from "../pages/Program/Programs";
import CoachAdd from "../pages/Coach/Add";
import CoachEdit from "../pages/Coach/Edit";
import Coachs from "../pages/Coach/Coachs";
import Setting from '../pages/Setting/Setting';
import Users from '../pages/User/Users';
import Orders from '../pages/Order/Orders';
import OrderShow from '../pages/Order/Show';
import PageNotFound from '../pages/PageNotFound';
import Translate from "../pages/Translate/Translate";

//--------------------------------------------------

function Router()
{
    return (
        <Routes>
            <Route element={<RouterPublic />}>
                <Route path="/login" element={<Login />} />
                <Route path="/forget-password" element={<ForgetPassword />} />
            </Route>

            <Route exact path='/' element={<Navigate to="/programs" />} />

            <Route element={<RouterPrivate />}>
                <Route path="/programs" element={<Programs />} />
                <Route path="/program/add" element={<ProgramAdd />} />
                <Route path="/program/edit/:programId" element={<ProgramEdit />} />

                <Route path="/coachs" element={<Coachs />} />
                <Route path="/coach/add" element={<CoachAdd />} />
                <Route path="/coach/edit/:coachId" element={<CoachEdit />} />

                <Route path="/orders" element={<Orders />} />
                <Route path="/order/:orderId" element={<OrderShow />} />

                <Route path="/chats" element={<Chats />} />
                <Route path="/chat/:chatId" element={<Chat />} />

                <Route path="/cool-down" element={<CoolDown />} />

                <Route path="/warm-up" element={<WarmUp />} />

                <Route path="/users" element={<Users />} />

                <Route path="/translate" element={<Translate />} />

                <Route path="/setting" element={<Setting />} />

                <Route path="/profile" element={<Profile />} />
            </Route>

            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
}

export default Router;
