import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link, useNavigate, useParams} from "react-router-dom";
import SortTable from "../../components/SortTable/SortTable";
import Pagination from "../../components/Pagination/Pagination";
import CoachApi from "../../services/CoachApi";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import General from "../../utils/General";
import IconButton from '@mui/material/IconButton';
import {Button, Container, Grid, TextField} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import DialogQuestion from "../../components/DialogQuestion/DialogQuestion";
import AlertAction from "../../components/AlertAction";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import "./Coachs.css";
import UserApi from "../../services/UserApi";
import {DollarSquare, Trash, Edit, ArrowRight2} from "iconsax-react";

//--------------------------------------------------

function Coachs(props)
{
    // set page title & page path
    usePage().pageData({title: Menus.coachs.title, activeMenu: Menus.coachs.className});

    //
    const [coachData, setCoachData] = useState([]);
    const [coachDataLoading, setCoachDataLoading] = useState(true);
    const [paginationData, setPaginationData] = useState({"currentPageNumber": 0, "totalPages": 0, "totalRows": 0, "pageRows": 0, "currentPageRows": 0});

    const [sortData, setSortData] = useState({});
    const [sortColName, setSortColName] = useState('');
    const [sortType, setSortType] = useState('');

    const [coachId, setCoachId] = useState('');
    const [searchWord, setSearchWord] = useState('');

    const navigate = useNavigate();
    const alertAction = useRef(); // init alert
    const dialogQuestion = useRef(); // init alert delete

    // load data
    const loadData = (pageNumber = 1) =>
    {
        setCoachData(null); // set data to null
        setCoachDataLoading(true); // show loading

        // get data
        CoachApi.list({ pageNumber: pageNumber }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setPaginationData({"currentPageNumber": response.data.currentPageNumber, "totalPages": response.data.totalPages, "totalRows": response.data.totalRows, "pageRows": response.data.pageRows, "currentPageRows": response.data.currentPageRows});

                    setCoachData(response.data.list);
                }

                setCoachDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setCoachDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    //
    const handleCoachDelete = (id) =>
    {
        dialogQuestion.current.show();

        setCoachId(id); // set coach id for delete
    };

    const coachDelete = () =>
    {
        dialogQuestion.current.hide(); // hide popup
        setCoachDataLoading(true); // show loading

        // get data
        CoachApi.destroy(coachId).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    alertAction.current.showAlert("Coach Deleted", "success"); // show success

                    const pn = General.initPageNumber(paginationData);
                    if(pn > 0)
                    {
                        loadData(pn);
                    }
                    else
                    {
                        setCoachData(null);
                        setCoachDataLoading(false); // hide loading
                    }
                }
                else
                {
                    // alertAction.current.showAlert("Incorrect information"); // show error
                    alertAction.current.showAlert(response.message); // show error

                    setCoachDataLoading(false); // hide loading
                }
            }
        ).catch
        (
            function(error)
            {
                setCoachDataLoading(false); // hide loading
                alertAction.current.showAlert("There is a Problem"); // show error
            }
        );
    };

    //
    return (
        <div className="page-content page-coachs">
            <div className="section-title">
                <div className="title-box">
                    <span className="title">Coach </span>
                    <span className="title-t2">List</span>
                </div>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    coachDataLoading ?
                        null
                        :
                        (
                            coachData !== null && coachData.length > 0 ?
                                <table className="table-data">
                                    <thead className="sticky">
                                    <tr>
                                        <th>title</th>
                                        <th>Priority</th>
                                        <th className="w-80"></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        coachData.map
                                        (
                                            (item, index) =>
                                            {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.titleEn}</td>
                                                        <td>{item.priority}</td>
                                                        <td>
                                                            <Link to={'/coach/edit/' + item.id}><Edit className="btn-icon-delete m-r-8" variant="Bulk" /></Link>
                                                            <Trash className="btn-icon-delete m-l-16" variant="Bulk" onClick={(e) => handleCoachDelete(item.id)} />
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    }
                                    </tbody>
                                </table>
                                :
                                <AlertDataEmpty />
                        )
                }


                {/* loading */}
                {coachDataLoading ? <LoadingOver /> : null}


                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>


            {
                coachData !== null && coachData.length > 0 ?
                    <div className="section-footer">
                        <Pagination data={paginationData} loadData={loadData} />
                    </div>
                    :
                    null
            }

            {/* popup delete */}
            <DialogQuestion ref={dialogQuestion} accept={coachDelete} title="Delete Coach" des="Are you sure you want to delete the coach?" buttonType="error" buttonAcceptText="Yes, Delete Coach" buttonCloseText="No" />
        </div>
    );
}

export default Coachs;
