import React, { forwardRef, useState, useImperativeHandle } from 'react';
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import {Button} from "@mui/material";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import "./DialogQuestion.css";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import parse from 'html-react-parser';
import {CloseSquare} from 'iconsax-react';

//--------------------------------------------------

const DialogQuestion = forwardRef((props, ref) =>
    {
        const [popup, setPopup] = useState(false);
        const [loading, setLoading] = useState(false);

        useImperativeHandle
        (
            ref, () =>
                (
                    {
                        show: () => { setPopup(true); },
                        hide: () => { setPopup(false); },
                        showLoading: () => { setLoading(true); },
                        hideLoading: () => { setLoading(false); }
                    }
                )
        );

        //
        return(
            <Dialog className="dialog dialog-question" open={popup} onClose={() => setPopup(false)} fullWidth={true} maxWidth='xs'>
                <DialogTitle className="dialog-title">
                    <span className="title">{props.title}</span>

                    <CloseSquare className="dialog-close" variant="TwoTone" onClick={() => setPopup(false)} />
                </DialogTitle>

                <DialogContent className="dialog-content">{parse(props.des)}</DialogContent>

                {
                    props.buttonAcceptText !== '' || props.buttonCloseText !== '' ?
                        <DialogActions className="dialog-actions">
                            {props.buttonAcceptText !== '' ? <Button onClick={props.accept} color={props.buttonType} variant="contained">{props.buttonAcceptText}</Button> : null}
                            {props.buttonCloseText !== '' ? <Button onClick={() => setPopup(false)} color={props.buttonType} variant="outlined">{props.buttonCloseText}</Button> : null}
                        </DialogActions>
                        :
                        null
                }
            </Dialog>
        );
    }
);

export default DialogQuestion;
