import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import AlertAction from "../../components/AlertAction";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import Menus from "../../utils/Menu";
import "./Users.css";
import UserApi from "../../services/UserApi";
import Pagination from "../../components/Pagination/Pagination";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";

//--------------------------------------------------

function Users(props)
{
    // set page title & page path
    usePage().pageData({title: Menus.users.title, activeMenu: Menus.users.className});

    //
    const alertAction = useRef(); // init alert

    const [userDataLoading, setUserDataLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [paginationData, setPaginationData] = useState(null);

    // load data
    const loadData = (pageNumber = 1) =>
    {
        setUserData(null); // set data to null
        setUserDataLoading(true); // show loading

        // get data
        UserApi.list({ pageNumber: pageNumber }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setPaginationData({"currentPageNumber": response.data.currentPageNumber, "totalPages": response.data.totalPages, "totalRows": response.data.totalRows, "pageRows": response.data.pageRows, "currentPageRows": response.data.currentPageRows});

                    setUserData(response.data.list);
                }

                setUserDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setUserDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    //
    return (
        <div className="page-content page-program-chat">
            <div className="section-title">
                <div className="title-box">
                    <span className="title">Users </span>
                    <span className="title-t2">List</span>
                </div>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    userDataLoading ?
                        null
                        :
                        (
                            userData !== null && userData.length > 0 ?
                                <table className="table-data">
                                    <thead className="sticky">
                                    <tr>
                                        <th className="">Name</th>
                                        <th className="w-180">Email</th>
                                        <th className="w-180">Phone</th>
                                        <th className="w-180">Country</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        userData.map
                                        (
                                            (item, index) =>
                                            {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.name}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.phone}</td>
                                                        <td>{item.country}</td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    }
                                    </tbody>

                                    {/*<tfoot className="sticky">*/}
                                    {/*<tr>*/}
                                    {/*    <td colSpan="10"><Pagination data={paginationData} loadData={loadData} /></td>*/}
                                    {/*</tr>*/}
                                    {/*</tfoot>*/}
                                </table>
                                :
                                <AlertDataEmpty />
                        )
                }


                {/* loading */}
                {userDataLoading ? <LoadingOver /> : null}

                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>

            {
                userData !== null && userData.length > 0 ?
                    <div className="section-footer">
                        <Pagination data={paginationData} loadData={loadData} />
                    </div>
                    :
                    null
            }
        </div>
    );
}

export default Users;
