import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import parse from 'html-react-parser';
import CoolDownApi from "../../services/CoolDownApi";
import AlertAction from "../../components/AlertAction";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import Menus from "../../utils/Menu";
import "./CoolDown.css";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AdminApi from "../../services/AdminApi";
import General from "../../utils/General";

import { Editor } from '@tinymce/tinymce-react';

//--------------------------------------------------

function CoolDown(props)
{
    // set page title & page path
    usePage().pageData({title: Menus.coolDown.title, activeMenu: Menus.coolDown.className});

    //
    const alertAction = useRef(); // init alert

    const [coolDownDataLoading, setCoolDownDataLoading] = useState(true);
    const [coolDownData, setCoolDownData] = useState(null);

    const [activeLanguage, setActiveLanguage] = useState('en');

    const [formLoading, setFormLoading] = useState(false);
    const [formDescriptionEn, setFormDescriptionEn] = useState('');
    const [formDescriptionFa, setFormDescriptionFa] = useState('');
    const [formCaptionEn, setFormCaptionEn] = useState('');
    const [formCaptionFa, setFormCaptionFa] = useState('');
    const [formImage, setFormImage] = useState('');
    const [formVideo, setFormVideo] = useState('');
    const [formVideoPoster, setFormVideoPoster] = useState('');

    const [formImageName, setFormImageName] = useState('');
    const [formVideoName, setFormVideoName] = useState('');
    const [formVideoPosterName, setFormVideoPosterName] = useState('');

    const editorEnRef = useRef(null);
    const editorFaRef = useRef(null);

    // load data
    const loadData = () =>
    {
        setCoolDownDataLoading(true); // show loading

        // get data
        CoolDownApi.show().then
        (
            function(response)
            {
                if (response.status === 200 && response.data !== null)
                {
                    setFormDescriptionEn(response.data.descriptionEn);
                    setFormDescriptionFa(response.data.descriptionFa);
                    setFormCaptionEn(response.data.captionEn);
                    setFormCaptionFa(response.data.captionFa);
                }

                setCoolDownDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setCoolDownDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    // handle tab
    const handleTabLanguageChange = (event, newValue) =>
    {
        setActiveLanguage(newValue);
    };

    // send data to server
    const submitCoolDown = () =>
    {
        if(formDescriptionEn !== "" && formDescriptionFa) // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            let params = new FormData();
            params.append('formDescriptionEn', formDescriptionEn);
            params.append('formDescriptionFa', formDescriptionFa);
            params.append('formCaptionEn', formCaptionEn);
            params.append('formCaptionFa', formCaptionFa);
            params.append('formImage', formImage);
            params.append('formVideo', formVideo);
            params.append('formVideoPoster', formVideoPoster);
            CoolDownApi.save(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Cool Down Updated", "success"); // show success
                    }
                    else
                    {
                        alertAction.current.showAlert(response.message); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete description"); // show error
        }
    };

    //
    return (
        <div className="page-content page-cool-down">
            <div className="section-title">
                <div className="title-box">
                    <span className="title">how to </span>
                    <span className="title-t2">Cool Down</span>
                </div>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                <Tabs className="tab-language" value={activeLanguage} onChange={handleTabLanguageChange}>
                    <Tab value="en" label="En" />
                    <Tab value="fa" label="Fa" />
                </Tabs>

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-input-box">
                                {/*<label className="form-label">Description</label>*/}
                                {
                                    activeLanguage === 'en' ?
                                        <Editor outputFormat='text' onEditorChange={(newText) => setFormDescriptionEn(newText)} value={formDescriptionEn} init={General.editorInit} />
                                        :
                                        <Editor outputFormat='text' onEditorChange={(newText) => setFormDescriptionFa(newText)} value={formDescriptionFa} init={General.editorInit} />
                                }
                                {/*{*/}
                                {/*    activeLanguage === 'en' ?*/}
                                {/*        <textarea dir="ltr" className="form-textarea no-icon h-300" value={formDescriptionEn} onChange={(e) => setFormDescriptionEn(e.target.value)}/>*/}
                                {/*        :*/}
                                {/*        <textarea dir="rtl" className="form-textarea no-icon h-300" value={formDescriptionFa} onChange={(e) => setFormDescriptionFa(e.target.value)}/>*/}
                                {/*}*/}
                                {/*<div className="form-hint">insert [%-image-%] for image - insert [%-video-%] for video</div>*/}
                            </div>
                        </div>

                        {/*<div className="col-12">*/}
                        {/*    <div className="form-input-box">*/}
                        {/*        <label className="form-label">Video Caption</label>*/}
                        {/*        {*/}
                        {/*            activeLanguage === 'en' ?*/}
                        {/*                <input type="text" dir="ltr" className="form-input" value={formCaptionEn} onChange={(e) => setFormCaptionEn(e.target.value)} />*/}
                        {/*                :*/}
                        {/*                <input type="text" dir="rtl" className="form-input" value={formCaptionFa} onChange={(e) => setFormCaptionFa(e.target.value)} />*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className="col-12">*/}
                        {/*    <div className="form-input-box">*/}
                        {/*        <label className="form-label">Image</label>*/}
                        {/*        <label className="btn-file" htmlFor="form-image">Select Image {formImageName !== '' ? ':: ' + formImageName : ''}<input type="file" id="form-image" onChange={(e) => { if(e.target.files.length > 0) { setFormImage(e.target.files[0]); setFormImageName(e.target.files[0].name); } } } /></label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className="col-12">*/}
                        {/*    <div className="form-input-box">*/}
                        {/*        <label className="form-label">Video</label>*/}
                        {/*        <label className="btn-file" htmlFor="form-video">Select Video {formVideoName !== '' ? ':: ' + formVideoName : ''}<input type="file" id="form-video" onChange={(e) => { if(e.target.files.length > 0) { setFormVideo(e.target.files[0]); setFormVideoName(e.target.files[0].name); } } } /></label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className="col-12">*/}
                        {/*    <div className="form-input-box">*/}
                        {/*        <label className="form-label">Video Poster</label>*/}
                        {/*        <label className="btn-file" htmlFor="form-video-poster">Select Video Poster {formVideoPosterName !== '' ? ':: ' + formVideoPosterName : ''}<input type="file" id="form-video-poster" onChange={(e) => { if(e.target.files.length > 0) { setFormVideoPoster(e.target.files[0]); setFormVideoPosterName(e.target.files[0].name); } } } /></label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>

                {/* loading */}
                {coolDownDataLoading || formLoading ? <LoadingOver /> : null}

                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>

            <div className="section-footer flex-end">
                <button onClick={submitCoolDown} type="button" className="btn-custom t2">Save Change</button>
            </div>
        </div>
    );
}

export default CoolDown;
