import RestApi from "./RestApi";

export default
{
    login: async function(params) { return RestApi.post("/login", params, false); },
    logout: async function(params) { return RestApi.post("/logout", params, false); },
    forgetPassword: async function(params) { return RestApi.post("/forget-password", params, false); },
    forgetPasswordSendVerifyCode: async function(params) { return RestApi.post("/forget-password/send-verify-code", params, false); },
    profile: async function(params) { return RestApi.put("/profile", params, true); },
}
