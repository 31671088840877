import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import React, {useEffect, useRef, useState} from "react";
import AlertAction from "../../components/AlertAction";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import Menus from "../../utils/Menu";
import "./Chats.css";
import ChatApi from "../../services/ChatApi";
import Pagination from "../../components/Pagination/Pagination";
import AlertDataEmpty from "../../components/AlertDataEmpty/AlertDataEmpty";
import {Eye, UserSquare} from 'iconsax-react';
import {Link} from "react-router-dom";

//--------------------------------------------------

function Chats(props)
{
    // set page title & page path
    usePage().pageData({title: Menus.chat.title, activeMenu: Menus.chat.className});

    //
    const alertAction = useRef(); // init alert

    const [chatDataLoading, setChatDataLoading] = useState(true);
    const [chatData, setChatData] = useState(null);
    const [paginationData, setPaginationData] = useState(null);

    // load data
    const loadData = (pageNumber = 1) =>
    {
        setChatData(null); // set data to null
        setChatDataLoading(true); // show loading

        // get data
        ChatApi.list({ pageNumber: pageNumber }).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setPaginationData({"currentPageNumber": response.data.currentPageNumber, "totalPages": response.data.totalPages, "totalRows": response.data.totalRows, "pageRows": response.data.pageRows, "currentPageRows": response.data.currentPageRows});

                    setChatData(response.data.list);
                }

                setChatDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setChatDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    //
    return (
        <div className="page-content page-program-chat">
            <div className="section-title">
                <div className="title-box">
                    <span className="title">ONLINE CHAT </span>
                    <span className="title-t2">List</span>
                </div>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                {
                    chatDataLoading ?
                        null
                        :
                        (
                            chatData !== null && chatData.length > 0 ?
                                <table className="table-data">
                                    <thead className="sticky">
                                    <tr>
                                        <th className="">Name</th>
                                        <th className="w-180">Time</th>
                                        <th className="w-40"></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        chatData.map
                                        (
                                            (item, index) =>
                                            {
                                                return (
                                                    <tr key={index} className={item.new ? "new" : ""}>
                                                        <td>{item.userName}</td>
                                                        <td>{item.dateTime}</td>
                                                        <td><Link to={'/chat/' + item.id} className="btn-link"><Eye className="icon" variant="TwoTone" /></Link></td>
                                                    </tr>
                                                );
                                            }
                                        )
                                    }
                                    </tbody>
                                </table>
                                :
                                <AlertDataEmpty />
                        )
                }


                {/* loading */}
                {chatDataLoading ? <LoadingOver /> : null}

                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>

            {
                chatData !== null && chatData.length > 0 ?
                    <div className="section-footer">
                        <Pagination data={paginationData} loadData={loadData} />
                    </div>
                    :
                    null
            }
        </div>
    );
}

export default Chats;
