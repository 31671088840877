import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useEffect, useRef, useState} from "react";
import AlertAction from "../../components/AlertAction";
import ProgramApi from "../../services/ProgramApi";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import "./Edit.css";
import {DollarSquare, Trash, AddSquare, Sort} from "iconsax-react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {useParams} from "react-router";
import General from "../../utils/General";

//--------------------------------------------------

function Edit(props)
{
    // get id
    let { programId } = useParams();

    // set page title & page path
    usePage().pageData({title: Menus.programs.title, activeMenu: Menus.programs.className});

    //
    const alertAction = useRef(); // init alert

    const [programDataLoading, setProgramDataLoading] = useState(true);
    const [activeLanguage, setActiveLanguage] = useState('en');

    const [formLoading, setFormLoading] = useState(false);
    const [formPrice, setFormPrice] = useState("");
    const [formPriceToman, setFormPriceToman] = useState("");
    const [formPriority, setFormPriority] = useState("");
    const [formTitleEn, setFormTitleEn] = useState("");
    const [formTitleFa, setFormTitleFa] = useState("");
    const [formImage, setFormImage] = useState("");
    const [formImageSrc, setFormImageSrc] = useState("");
    const [formImageName, setFormImageName] = useState("");
    const [formSpecialize, setFormSpecialize] = useState("");
    const [formProgramType, setFormProgramType] = useState("");
    const [formTrainingProgram, setFormTrainingProgram] = useState("");
    const [formGainLoss, setFormGainLoss] = useState("");
    const [formGender, setFormGender] = useState("");

    const [formDescription, setFormDescription] = useState({'en': '', 'fa': ''});

    const foodItems = {'stephan': '', 'time': '', 'diet': '', 'supplement': ''};
    const [formProgramFood, setFormProgramFood] = useState({'en': [foodItems, foodItems, foodItems], 'fa': [foodItems, foodItems, foodItems]});

    const workoutItems =
        [
            {'title': 'Cardio', 'des': ''},
            {'title': 'Crunches', 'des': ''},
            {'title': 'Reverse cunch', 'des': ''},
            {'title': 'Russian twist', 'des': ''},
            {'title': 'Dumbbell chest press', 'des': ''},
            {'title': 'Chest fly machine', 'des': ''},
            {'title': 'Inclined chest press', 'des': ''},
        ];
    const [formProgramWorkout, setFormProgramWorkout] = useState({'en': {0: workoutItems, 1: workoutItems}, 'fa': {0: workoutItems, 1: workoutItems}});

    // load data
    const loadData = () =>
    {
        setProgramDataLoading(true); // show loading

        // get data
        ProgramApi.show(programId).then
        (
            function(response)
            {
                if (response.status === 200)
                {
                    setFormTitleEn(response.data.titleEn);
                    setFormTitleFa(response.data.titleFa);
                    setFormImageSrc(response.data.image);
                    setFormSpecialize(response.data.special);
                    setFormGender(response.data.gender === 1 ? 1 : 0);
                    setFormGainLoss(response.data.gainLoss);
                    setFormProgramType(response.data.foodWorkout);
                    setFormTrainingProgram(response.data.homeGym);
                    setFormPrice(response.data.price);
                    setFormPriceToman(response.data.priceToman);
                    setFormPriority(response.data.priority);
                    setFormDescription({...response.data.programDescription});
                    setFormProgramFood({...response.data.programFood});
                    setFormProgramWorkout({...response.data.programWorkout});
                }

                setProgramDataLoading(false); // hide loading
            }
        ).catch
        (
            function(error)
            {
                setProgramDataLoading(false); // hide loading
            }
        );
    };

    useEffect(() => { loadData(); }, []);

    // send data to server
    const submitProgram = () =>
    {
        let priceToman = (formPriceToman !== '') ? formPriceToman.toString().replace(/,/g, '') : 0;

        if(formTitleEn !== "" && formTitleFa !== "" /*&& formImageName !== ""*/ && formSpecialize !== "" && formProgramType !== "" && formTrainingProgram !== "" && formGainLoss !== "" && formGender !== "" && formPrice > 0 && priceToman > 0) // check data is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            let params = new FormData();
            params.append('formTitleEn', formTitleEn);
            params.append('formTitleFa', formTitleFa);
            params.append('formImage', formImage);
            params.append('formSpecialize', formSpecialize);
            params.append('formProgramType', formProgramType);
            params.append('formTrainingProgram', formTrainingProgram);
            params.append('formGainLoss', formGainLoss);
            params.append('formGender', formGender);
            params.append('formPrice', formPrice);
            params.append('formPriceToman', priceToman);
            params.append('formPriority', formPriority);
            params.append('formDescription', formDescription);
            params.append('formProgramFood', formProgramFood);
            params.append('formProgramWorkout', formProgramWorkout);
            ProgramApi.update(programId, params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Program Updated", "success"); // show success
                    }
                    else
                    {
                        alertAction.current.showAlert(response.message); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    // handle tab
    const handleTabLanguageChange = (event, newValue) =>
    {
        setActiveLanguage(newValue);
    };

    // handle Description
    const handleDescription = (e) =>
    {
        let tmp = {...formDescription};
        tmp[activeLanguage] = e.target.value;

        setFormDescription({...tmp});
    };

    // handle Program Food
    const handleProgramFood = (e, index, itemName) => // set input value
    {
        let tmp = {...formProgramFood};
        let tmp2 = [...tmp[activeLanguage]];
        let tmp3 = {...tmp2[index]};

        tmp3[itemName] = e.target.value;
        tmp2[index] = {...tmp3};
        tmp[activeLanguage] = [...tmp2, ...[]];

        setFormProgramFood({...tmp});
    };

    const handleProgramFoodAddRow = () => // add row
    {
        let tmp = {...formProgramFood};
        let tmp2 = [...tmp[activeLanguage]];

        tmp2[tmp2.length] = {...foodItems};
        tmp[activeLanguage] = [...tmp2, ...[]];

        setFormProgramFood({...tmp});
    };

    const handleProgramFoodRemoveRow = (index) => // remove row
    {
        let tmp = {...formProgramFood};
        let tmp2 = [...tmp[activeLanguage]];

        tmp2.splice(index, 1);
        tmp[activeLanguage] = [...tmp2, ...[]];

        setFormProgramFood({...tmp});
    };

    // handle Program Workout
    const handleProgramWorkout = (e, index, index2, itemName) => // set input value
    {
        let tmp = {...formProgramWorkout};
        let tmp2 = {...tmp[activeLanguage]};
        let tmp3 = [...tmp2[index]];
        let tmp4 = {...tmp3[index2]};

        tmp4[itemName] = e.target.value;
        tmp3[index2] = {...tmp4};
        tmp2[index] = [...tmp3, ...[]];
        tmp[activeLanguage] = {...tmp2};

        setFormProgramWorkout({...tmp});
    };

    const handleProgramWorkoutAddRow = () => // add row
    {
        let tmp = {...formProgramWorkout};
        let tmp2 = {...tmp[activeLanguage]};

        tmp2[parseInt(Object.keys(tmp2).pop()) + 1] = [...workoutItems];
        tmp[activeLanguage] = {...tmp2};

        setFormProgramWorkout({...tmp});
    };

    const handleProgramWorkoutRemoveRow = (index) => // remove row
    {
        let tmp = {...formProgramWorkout};
        let tmp2 = {...tmp[activeLanguage]};

        delete tmp2[index];
        tmp[activeLanguage] = {...tmp2};

        setFormProgramWorkout({...tmp});
    };

    const handleProgramWorkoutAddDayRow = (index) => // add row
    {
        let tmp = {...formProgramWorkout};
        let tmp2 = {...tmp[activeLanguage]};
        let tmp3 = [...tmp2[index]];

        tmp3[tmp3.length] = {...{'title': '', 'des': ''}};
        tmp2[index] = [...tmp3];
        tmp[activeLanguage] = {...tmp2};

        setFormProgramWorkout({...tmp});
    };

    const handleProgramWorkoutRemoveDayRow = (index, index2) => // remove row
    {
        let tmp = {...formProgramWorkout};
        let tmp2 = {...tmp[activeLanguage]};
        let tmp3 = [...tmp2[index]];

        tmp3.splice(index2, 1);
        tmp2[index] = [...tmp3, ...[]];
        tmp[activeLanguage] = {...tmp2};

        setFormProgramWorkout({...tmp});
    };

    // reset
    const resetForm = () =>
    {
        setFormDescription({...{'en': '', 'fa': ''}});
        setFormProgramFood({...{'en': [foodItems, foodItems, foodItems], 'fa': [foodItems, foodItems, foodItems]}});
        setFormProgramWorkout({...{'en': {0: workoutItems, 1: workoutItems}, 'fa': {0: workoutItems, 1: workoutItems}}});
    };

    // handle image
    const handleImageChange = (e) =>
    {
        if(e.target.files && e.target.files[0])
        {
            let reader = new FileReader();
            reader.onload = (e2) =>
            {
                setFormImage(e.target.files[0]);
                setFormImageName(e.target.files[0].name);
                setFormImageSrc(e2.target.result);
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    //
    return (
        <div className="page-content page-program-edit">
            <div className="section-title">
                <div className="title-box">
                    <span className="title">Add </span>
                    <span className="title-t2">Program</span>
                </div>
            </div>

            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                <div className="container">
                    <div className="row">
                        {/*<div className="col-6">*/}
                        {/*    <div className="form-input-box display-flex">*/}
                        {/*        <div className="form-label">Specialize Program</div>*/}
                        {/*        <label className="input-radio-box w-140"><input type="radio" checked={formSpecialize === 1} onChange={(e) => setFormSpecialize(1)} /> Specialize</label>*/}
                        {/*        <label className="input-radio-box w-140"><input type="radio" checked={formSpecialize === 0} onChange={(e) => setFormSpecialize(0)} /> Non-Specialize</label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="col-6">
                            <div className="form-input-box display-flex">
                                <div className="form-label">Program Type</div>
                                <label className="input-radio-box w-140"><input type="radio" checked={formProgramType === 0} onChange={(e) => { setFormProgramType(0); resetForm(); }} /> Food</label>
                                <label className="input-radio-box w-140"><input type="radio" checked={formProgramType === 1} onChange={(e) => { setFormProgramType(1); resetForm(); }} /> Workout</label>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-input-box display-flex">
                                <div className="form-label">Training Program</div>
                                <label className="input-radio-box w-140"><input type="radio" checked={formTrainingProgram === 0} onChange={(e) => setFormTrainingProgram(0)} /> Home</label>
                                <label className="input-radio-box w-140"><input type="radio" checked={formTrainingProgram === 1} onChange={(e) => setFormTrainingProgram(1)} /> Gym</label>
                                <label className="input-radio-box w-140"><input type="radio" checked={formTrainingProgram === 2} onChange={(e) => setFormTrainingProgram(2)} /> Champion</label>
                            </div>
                        </div>

                        {/*<div className="col-6">*/}
                        {/*    <div className="form-input-box display-flex">*/}
                        {/*        <div className="form-label">Gain or Loss</div>*/}
                        {/*        <label className="input-radio-box w-140"><input type="radio" checked={formGainLoss === 0} onChange={(e) => setFormGainLoss(0)} /> Gain</label>*/}
                        {/*        <label className="input-radio-box w-140"><input type="radio" checked={formGainLoss === 1} onChange={(e) => setFormGainLoss(1)} /> Loss</label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className="col-6">*/}
                        {/*    <div className="form-input-box display-flex">*/}
                        {/*        <div className="form-label">Gender</div>*/}
                        {/*        <label className="input-radio-box w-140"><input type="radio" checked={formGender === 0} onChange={(e) => setFormGender(0)} /> Woman</label>*/}
                        {/*        <label className="input-radio-box w-140"><input type="radio" checked={formGender === 1} onChange={(e) => setFormGender(1)} /> Man</label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="col-6">
                            <div className="form-label">Title En</div>
                            <div className="form-input-box">
                                <input type="text" className="form-input" value={formTitleEn} onChange={(e) => setFormTitleEn(e.target.value)} />
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-label">Title Fa</div>
                            <div className="form-input-box">
                                <input type="text" className="form-input" value={formTitleFa} onChange={(e) => setFormTitleFa(e.target.value)} dir="rtl" />
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-label">Price Dollar</div>
                            <div className="form-input-box">
                                <input type="text" className="form-input" placeholder="Price Dollar" value={formPrice} onChange={(e) => setFormPrice(e.target.value)} />
                                <DollarSquare variant="TwoTone" className="icon" />
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-label">Price Toman</div>
                            <div className="form-input-box">
                                <input type="text" className="form-input" placeholder="Price Toman" value={General.priceFormat(formPriceToman)} onChange={(e) => setFormPriceToman(e.target.value)} />
                                <span className="icon">T</span>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-label">Priority</div>
                            <div className="form-input-box">
                                <input type="text" className="form-input" placeholder="Priority" value={formPriority} onChange={(e) => setFormPriority(e.target.value)} />
                                <Sort variant="TwoTone" className="icon" />
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="form-input-box">
                                <label className="form-label">Image</label>
                                <label className="btn-file" htmlFor="form-image">Select Image {formImageName !== '' ? ':: ' + formImageName : ''}<input type="file" id="form-image" onChange={(e) => { if(e.target.files.length > 0) handleImageChange(e) } } /></label>
                            </div>

                            {formImageSrc !== "" ? <img src={formImageSrc} className="w-max-300" /> : null}
                        </div>
                    </div>

                    {/* program is specialize */}
                    {
                        formSpecialize === 0 ?
                            <div className="row">
                                <div className="col-12">
                                    <Tabs className="tab-language" value={activeLanguage} onChange={handleTabLanguageChange}>
                                        <Tab value="en" label="En" />
                                        <Tab value="fa" label="Fa" />
                                    </Tabs>
                                </div>

                                <div className="col-12">
                                    <div className="form-input-box">
                                        <label className="form-label">Description</label>
                                        {
                                            <textarea dir={activeLanguage === 'en' ? "ltr" : "rtl"} className="form-textarea no-icon h-100" value={formDescription[activeLanguage]} onChange={(e) => handleDescription(e)}/>
                                        }
                                    </div>
                                </div>

                                {/* program is food or workout */}
                                {
                                    formProgramType === 0 ?
                                        <div className="col-12 program-list">
                                            <div className="row row-head">
                                                <div className="col-3">Stephan</div>
                                                <div className="col-3">Time</div>
                                                <div className="col-3">Diet</div>
                                                <div className="col-3">Supplement</div>
                                            </div>
                                            {
                                                formProgramFood[activeLanguage].map
                                                (
                                                    (item, index) =>
                                                    {
                                                        return (
                                                            <div className="row row-body" key={index}>
                                                                <div className="col-3"><input className="form-input no-icon" value={item['stephan']} onChange={(e) => handleProgramFood(e, index, 'stephan')} /></div>
                                                                <div className="col-3"><input className="form-input no-icon" value={item['time']} onChange={(e) => handleProgramFood(e, index, 'time')} /></div>
                                                                <div className="col-3"><input className="form-input no-icon" value={item['diet']} onChange={(e) => handleProgramFood(e, index, 'diet')} /></div>
                                                                <div className="col-3"><input className="form-input" value={item['supplement']} onChange={(e) => handleProgramFood(e, index, 'supplement')} /></div>
                                                                {index > 0 ? <Trash className="btn-icon-delete" variant="Bulk" onClick={(e) => handleProgramFoodRemoveRow(index)} /> : null}
                                                            </div>
                                                        );
                                                    }
                                                )
                                            }
                                            <div className="row row-body">
                                                <div className="col-12"><AddSquare className="btn-icon-add" variant="Bulk" onClick={handleProgramFoodAddRow} /></div>
                                            </div>
                                        </div>
                                        :
                                        (
                                            formProgramType === 1 ?
                                                <div className="col-12 program-list">
                                                    {
                                                        Object.keys(formProgramWorkout[activeLanguage]).map
                                                        (
                                                            (key, index) =>
                                                            {
                                                                return (
                                                                    <div key={index}>
                                                                        <div className="row row-head t2" key={index}>
                                                                            <div className="col-12">Day {index + 1}</div>
                                                                            {index > 0 ? <Trash className="btn-icon-delete" variant="Bulk" onClick={(e) => handleProgramWorkoutRemoveRow(key)} /> : null}
                                                                        </div>
                                                                        {
                                                                            formProgramWorkout[activeLanguage][key].map
                                                                            (
                                                                                (item2, index2) =>
                                                                                {
                                                                                    return (
                                                                                        <div className="row row-body" key={index2}>
                                                                                            <div className="col-6"><input className="form-input no-icon" value={item2['title']} onChange={(e) => handleProgramWorkout(e, key, index2, 'title')} /></div>
                                                                                            <div className="col-6"><input className="form-input no-icon" value={item2['des']} onChange={(e) => handleProgramWorkout(e, key, index2, 'des')} /></div>
                                                                                            {index2 > 0 ? <Trash className="btn-icon-delete" variant="Bulk" onClick={(e) => handleProgramWorkoutRemoveDayRow(key, index2)} /> : null}
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            )
                                                                        }
                                                                        <div className="row row-body t2">
                                                                            <div className="col-12"><AddSquare className="btn-icon-add" variant="Bulk" onClick={() => handleProgramWorkoutAddDayRow(key)} /></div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )

                                                        // formProgramWorkout[activeLanguage].map
                                                        // (
                                                        //     (item, index) =>
                                                        //     {
                                                        //         return (
                                                        //             <div key={index}>
                                                        //                 <div className="row row-head t2" key={index}>
                                                        //                     <div className="col-12">Day {index + 1}</div>
                                                        //                     {index > 0 ? <Trash className="btn-icon-delete" variant="Bulk" onClick={(e) => handleProgramWorkoutRemoveRow(index)} /> : null}
                                                        //                 </div>
                                                        //                 {
                                                        //                     item.map
                                                        //                     (
                                                        //                         (item2, index2) =>
                                                        //                         {
                                                        //                             return (
                                                        //                                 <div className="row row-body" key={index2}>
                                                        //                                     <div className="col-6"><input className="form-input no-icon" value={item2['title']} onChange={(e) => handleProgramWorkout(e, index, index2, 'title')} /></div>
                                                        //                                     <div className="col-6"><input className="form-input no-icon" value={item2['des']} onChange={(e) => handleProgramWorkout(e, index, index2, 'des')} /></div>
                                                        //                                 </div>
                                                        //                             );
                                                        //                         }
                                                        //                     )
                                                        //                 }
                                                        //             </div>
                                                        //         );
                                                        //     }
                                                        // )
                                                    }
                                                    <div className="row row-body">
                                                        <div className="col-12"><AddSquare className="btn-icon-add" variant="Bulk" onClick={handleProgramWorkoutAddRow} /></div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        )
                                }
                            </div>
                            :
                            null
                    }
                </div>


                {/* loading */}
                {formLoading || programDataLoading ? <LoadingOver /> : null}


                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>

            <div className="section-footer flex-end">
                <button onClick={submitProgram} type="button" className="btn-custom t2">Save Change</button>
            </div>
        </div>
    );
}

export default Edit;
