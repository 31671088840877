import { usePage } from "../../contexts/Page";
import { Scrollbars } from "react-custom-scrollbars-2";
import Menus from "../../utils/Menu";
import React, {useRef, useState} from "react";
import AlertAction from "../../components/AlertAction";
// import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Typography from '@mui/material/Typography';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import {Link} from "react-router-dom";
// import {Button, Container, Grid, InputLabel, TextField} from "@mui/material";
// import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
// import Loading from "../../components/Loading/Loading";
// import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import General from "../../utils/General";
import {useAuth} from "../../contexts/Auth";
import AdminApi from "../../services/AdminApi";
import LoadingOver from "../../components/LoadingOver/LoadingOver";
import "./Profile.css";
import {Mobile, Eye, EyeSlash, Sms, UserEdit} from "iconsax-react";
import {Link} from "react-router-dom";

//--------------------------------------------------

function Profile(props)
{
    // set page title & page path
    usePage().pageData({title: Menus.profile.title, activeMenu: Menus.profile.className});

    //
    const auth = useAuth();
    const alertAction = useRef(); // init alert

    const [formName, setFormName] = useState(General.admin !== undefined ? General.admin.name : "");
    const [formEmail, setFormEmail] = useState(General.admin !== undefined ? General.admin.email : "");
    const [formPhone, setFormPhone] = useState(General.admin !== undefined ? General.admin.phone : "");
    const [formPassword, setFormPassword] = useState("");
    const [formPasswordNew, setFormPasswordNew] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [passwordTypeNew, setPasswordTypeNew] = useState("password");
    const [formLoading, setFormLoading] = useState(false);

    // send data to server
    const submitProfile = () =>
    {
        if(formName !== "" && formPhone !== "" && formEmail !== "" && formPassword !== "") // check id & password is correct
        {
            setFormLoading(true); // hide btn & show loading

            // send data
            const params = {'name': formName, 'phone': formPhone, 'email': formEmail, 'password': formPassword, 'password-new': formPasswordNew};
            AdminApi.profile(params).then
            (
                function(response)
                {
                    if(response.status === 200)
                    {
                        alertAction.current.showAlert("Profile Updated", "success"); // show success
                        auth.authentication({admin: response.data.admin, name: response.data.name, token: response.data.token, isRemember: General.isRemember});
                    }
                    else
                    {
                        alertAction.current.showAlert(response.message); // show error
                    }

                    setFormLoading(false); // show btn & hide loading
                }
            ).catch
            (
                function(error)
                {
                    setFormLoading(false); // show btn & hide loading
                    alertAction.current.showAlert("There is a Problem"); // show error
                }
            );
        }
        else
        {
            alertAction.current.showAlert("Please complete information"); // show error
        }
    };

    //
    return (
        <div className="page-content page-profile">
            <div className="section-title">
                <div className="title-box">
                    <span className="title">Edit </span>
                    <span className="title-t2">Profile</span>
                </div>
            </div>


            <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true} className="scrollbar">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="form-input-box">
                                <input type="text" className="form-input" placeholder="Full Name" value={formName} onChange={(e) => setFormName(e.target.value)} />
                                <UserEdit variant="TwoTone" className="icon" />
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-input-box">
                                <input type="text" className="form-input" placeholder="Email" value={formEmail} onChange={(e) => setFormEmail(e.target.value)} />
                                <Sms variant="TwoTone" className="icon" />
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-input-box">
                                <input type="text" className="form-input" placeholder="Phone" value={formPhone} onChange={(e) => setFormPhone(e.target.value)} />
                                <Mobile variant="TwoTone" className="icon" />
                            </div>
                        </div>

                        <div className="col-6"></div>

                        <div className="col-6">
                            <div className="form-input-box">
                                <input type={passwordType} className="form-input" placeholder="Current Password" autoComplete="new-password" onChange={(e) => setFormPassword(e.target.value)} />
                                {passwordType === 'password' ? <Eye variant="TwoTone" className="icon" onClick={() => setPasswordType('text')} /> : <EyeSlash variant="TwoTone" className="icon" onClick={() => setPasswordType('password')} />}
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-input-box">
                                <input type={passwordTypeNew} className="form-input" placeholder="New Password" autoComplete="new-password-new" onChange={(e) => setFormPasswordNew(e.target.value)} />
                                {passwordTypeNew === 'password' ? <Eye variant="TwoTone" className="icon" onClick={() => setPasswordTypeNew('text')} /> : <EyeSlash variant="TwoTone" className="icon" onClick={() => setPasswordTypeNew('password')} />}
                            </div>
                        </div>
                    </div>
                </div>


                {/* loading */}
                {formLoading ? <LoadingOver /> : null}


                {/* alert */}
                <AlertAction ref={alertAction} />
            </Scrollbars>

            <div className="section-footer flex-end">
                <button onClick={submitProfile} type="button" className="btn-custom t2">Save Change</button>
            </div>
        </div>
    );
}

export default Profile;
