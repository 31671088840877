import React, {useEffect, useState} from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import { AuthContext, useAuth } from "./contexts/Auth";
import { PageContext, usePage } from "./contexts/Page";
import Router from "./routes/Router";
import General from "./utils/General";
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import Drawer from '@mui/material/Drawer';
import Tooltip from "@mui/material/Tooltip/Tooltip";
import UserApi from "./services/UserApi";
import "./styles/Main.css";
import Menu from "./components/Menu/Menu";
// import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { Send2, LogoutCurve, Home2, HambergerMenu } from 'iconsax-react';
import AdminApi from "./services/AdminApi";

//----------------------------------------------------------------------------------------------------------------------

function App(props)
{
    // admin auth
    const auth = useAuth();
    const uPage = usePage();
    const [authenticated, setAuthenticated] = useState(auth.checkAuthenticate);
    const [name, setName] = useState(auth.getName);
    const authentication = (data) =>
    {
        auth.setAuthenticate(data);
        if(data !== undefined && data !== null) setName(data.name);
        setAuthenticated(data !== undefined && data !== null);
    };

    //
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);

    // page data :: title & path
    const navigate = useNavigate();
    const [pageTitle, setPageTitle] = useState("");
    const [pageTitle2, setPageTitle2] = useState("");
    const [pageName, setPageName] = useState("");
    const [pagePath, setPagePath] = useState("");
    const [activeMenu, setActiveMenu] = useState("");
    const pageData = (data) =>
    {
        uPage.pageTitle = data.title;
        uPage.pageTitle2 = data.title2;
        uPage.pageName = data.pageName;
        setPageTitle(data.title);
        setPageTitle2(data.title2);
        setPageName(data.pageName);
        setPagePath(data.path);
        setActiveMenu(data.activeMenu);

        auth.token();
    };

    // notify
    let notifyTimeout = null;
    const [notifyOrders, setNotifyOrders] = useState(0);
    const [notifyTickets, setNotifyTickets] = useState(0);

    const loadNotifyData = () =>
    {
        // if(auth.checkAuthenticate())
        // {
        //     // get data
        //     NotifyApi.notify().then
        //     (
        //         function(response)
        //         {
        //             if (response.status === 200)
        //             {
        //                 setNotifyOrders(parseInt(response.data.orders));
        //                 setNotifyTickets(parseInt(response.data.tickets));
        //             }
        //         }
        //     ).catch
        //     (
        //         function(error)
        //         {
        //         }
        //     );
        //
        //     // repeat
        //     notifyTimeout = setTimeout(loadNotifyData, 60000);
        // }
        // else
        // {
        //     clearInterval(notifyTimeout);
        // }
    };

    useEffect(() => { notifyTimeout = setTimeout(loadNotifyData, 1000); }, [authenticated]);

    // log out
    function logOut()
    {
        AdminApi.logout();
        authentication(null);
    }

    //
    return (
        <AuthContext.Provider value={{ authenticated: authenticated, authentication: authentication }}>
            <PageContext.Provider value={{ pageData: pageData }}>
                    {
                        authenticated ?
                            (
                                <div className={pageName !== "" ? "main-body main-body-" + pageName : "main-body"}>
                                    <Menu activeMenu={activeMenu} name={name} logOut={logOut} />

                                    <div className="col-content">
                                        <div className="top-bar">
                                            <div className="menu-drawer-breadcrumb">
                                                <button className="icon-box btn-menu-drawer" onClick={() => setIsOpenDrawer(true)}><HambergerMenu className="icon" variant="TwoTone" /></button>

                                                <ul className="breadcrumb">
                                                    <li><Link to=""><Home2 className="icon" />Dashboard</Link></li>
                                                    <li>{pageTitle}</li>
                                                </ul>
                                            </div>

                                            <div className="col-icon">
                                                <Tooltip title="Logout" placement="bottom" arrow><button className="icon-box"><LogoutCurve className="icon" variant="TwoTone" onClick={logOut} /></button></Tooltip>
                                            </div>
                                        </div>

                                        <Router />
                                    </div>

                                    <Drawer className="menu-drawer" open={isOpenDrawer} onClose={() => setIsOpenDrawer(false)}>
                                        <Menu activeMenu={activeMenu} logOut={logOut} />
                                    </Drawer>
                                </div>
                            )
                            :
                            (
                                <div className="main-body">
                                    <Router />
                                </div>
                            )
                    }
            </PageContext.Provider>
        </AuthContext.Provider>
    );
}

export default App;
